const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  STORES_LIST: 'stores-list',
  AGENTS_LIST: 'agents-list',
  PRODUCES_LIST: 'produces-list',
  PRODUCETYPES_LIST: 'producetypes-list',
  STOCKS_LIST: 'stocks-list',
  STOCK_LINES_LIST: 'stocks-list',
  ORDERS_LIST: 'orders-list',
  CRONS_LIST: 'crons-list'
}

export {QUERIES}
