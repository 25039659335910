import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const RolesPage = lazy(
    () => import("../modules/apps/role-management/RolesPage")
  );
  const StoresPage = lazy(
    () => import("../modules/apps/store-management/StoresPage")
  );
  const ProduceTypesPage = lazy(
    () => import("../modules/apps/produce-type-management/ProduceTypesPage")
  );
  const AgentsPage = lazy(
    () => import("../modules/apps/agent-management/AgentsPage")
  );
  const ProducesPage = lazy(
    () => import("../modules/apps/produce-management/ProducesPage")
  );
  const StockOnHandPage = lazy(
    () => import("../modules/apps/stock-management/StocksPage")
  );
  const RequestOrderPage = lazy(
    () => import("../modules/apps/order-management/OrdersPage")
  );
  const AccessSettingPage = lazy(
    () => import("../modules/apps/access-setting/AccessSettingPage")
  );
  const CronSettingPage = lazy(
    () => import("../modules/apps/cron-setting/CronSettingPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/role-management/*"
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/store-management/*"
          element={
            <SuspensedView>
              <StoresPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/produce-type-management/*"
          element={
            <SuspensedView>
              <ProduceTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/agent-management/*"
          element={
            <SuspensedView>
              <AgentsPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/produce-management/*"
          element={
            <SuspensedView>
              <ProducesPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/stock-management/*"
          element={
            <SuspensedView>
              <StockOnHandPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/order-management/*"
          element={
            <SuspensedView>
              <RequestOrderPage />
            </SuspensedView>
          }
        />
        <Route
          path="/access-setting/*"
          element={
            <SuspensedView>
              <AccessSettingPage />
            </SuspensedView>
          }
        />
        <Route
          path="/cron-setting/*"
          element={
            <SuspensedView>
              <CronSettingPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
