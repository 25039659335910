import React from "react";
import { KTSVG } from "./KTSVG";

type Props = {
  funcGenerataPDF: () => void;
  funcGenerateXLS: () => void;
  loading?: boolean;
};

const defaultProps: Props = {
  funcGenerataPDF: () => null,
  funcGenerateXLS: () => null,
  loading: false,
};

const ExportButton: React.FC<Props> = (props) => {
  const { funcGenerataPDF, funcGenerateXLS, loading } = props;
  return (
    <div className="btn-group me-3">
      <button
        type="button"
        className="btn btn-light-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {loading ? (
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <KTSVG
              path="/media/icons/duotune/arrows/arr078.svg"
              className="svg-icon-2"
            />
            Export
          </>
        )}
      </button>

      <ul className="dropdown-menu">
        <li>
          <button
            className="btn dropdown-item"
            onClick={() => funcGenerataPDF()}
          >
            <KTSVG
              path="/media/icons/duotune/files/fil004.svg"
              className="svg-icon-2"
            />
            PDF
          </button>
        </li>
        <li>
          <button
            className="btn dropdown-item"
            onClick={() => funcGenerateXLS()}
          >
            <KTSVG
              path="/media/icons/duotune/files/fil002.svg"
              className="svg-icon-2"
            />
            Excel
          </button>
        </li>
      </ul>
    </div>
  );
};

ExportButton.defaultProps = defaultProps;

export { ExportButton };
