import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { PageSize, Orientation } from "@react-pdf/types";
import moment from "moment-timezone";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

type Header = {
  name?: string;
  key?: string;
  width?: string;
  isMerged?: boolean;
  oh?: any;
  req?: any;
};

type Props = {
  headers?: Header[];
  datas?: any[];
  storeName?: string;
  reportName?: string;
  name?: string;
  orientation?: Orientation;
  size?: PageSize;
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 8,
    padding: 2,
    paddingTop: 5,
    paddingBottom: 30,
  },

  header: {
    flexDirection: "row",
  },
  headerColumn: {
    width: "50%",
  },
  logo: {
    width: 150,
    height: 50,
    margin: "10 0 10 10",
  },
  title: {
    margin: "10 0 5 0",
    fontStyle: "bold",
    fontSize: 16,
  },
  storeName: {
    margin: "0 0 5 0",
    fontStyle: "bold",
    fontSize: 12,
  },
  date: {
    margin: "0 0 0 0",
    fontStyle: "bold",
    fontSize: 8,
  },
  divider: {
    width: "100%",
    borderWidth: 1,
    marginBottom: 5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  // End Of Header & Footer

  // Content
  headerRow: {
    flexDirection: "row",
    backgroundColor: "#bff0fd",
    textAlign: "left",
    borderTop: 1,
    borderLeft: 1,
  },
  dataRow: {
    flexDirection: "row",
    textAlign: "left",
    borderLeft: 1,
  },
  data: {
    width: "10%",
    padding: 5,
    borderRight: 1,
    borderBottom: 1,
  },
});

const PDFDocument: React.FC<Props> = ({
  headers = [],
  datas = [],
  storeName,
  reportName,
  name = "",
  orientation = "portrait",
  size = "A4",
}) => {
  return (
    <Document>
      <Page size={size} style={styles.page} orientation={orientation}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerColumn}>
            <Image
              style={styles.logo}
              src={toAbsoluteUrl("/media/logos/logo.png")}
            />
          </View>
          <View style={styles.headerColumn}>
            <Text style={styles.title}>GACO - {name} Data</Text>
            <Text style={styles.storeName}>
              {storeName ? storeName + " - " : null}
              {reportName}
            </Text>
            <Text style={styles.date}>
              Generated on : {moment().tz("Australia/Perth").format("llll")}
            </Text>
          </View>
        </View>
        {/* End Of Header */}

        <View style={styles.divider} />

        {/* Content */}
        <View>
          <View style={styles.headerRow} fixed wrap={false}>
            {headers.map((header, index) => {
              return header.isMerged ? (
                <View key={index} style={{ flexDirection: "column" }}>
                  <View style={{}}>
                    <Text
                      style={{
                        width: header.width,
                        textAlign: "center",
                        display: "flex",
                        borderLeft: 1,
                      }}
                    >
                      {header.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.headerRow,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: header.oh.width,
                        flex: `0 0 ${header.oh.width}`,
                        borderLeft: 1,
                        borderBottom: 1,
                      }}
                    >
                      <Text style={{ textAlign: "center" }}>
                        {header.oh.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: header.req.width,
                        flex: `0 0 ${header.req.width}`,
                        borderLeft: 1,
                        borderBottom: 1,
                      }}
                    >
                      <Text style={{ textAlign: "center" }}>
                        {header.req.name}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <Text
                  key={index}
                  style={{ ...styles.data, width: header.width }}
                >
                  {header.name}
                </Text>
              );
            })}
          </View>

          {datas.map((data, index) => {
            return (
              <View key={index} style={styles.dataRow} wrap={false}>
                {headers.map((header, index) => {
                  return header.isMerged ? (
                    <>
                      <Text
                        key={index}
                        style={{ ...styles.data, width: header.oh.width }}
                      >
                        {header.oh?.key?.includes(".")
                          ? header.oh.key
                              .split(".")
                              .reduce((a: string, b: any) => a[b], data)
                          : data[header.oh.key || ""]}
                      </Text>
                      <Text
                        key={index}
                        style={{ ...styles.data, width: header.req.width }}
                      >
                        {header.req?.key?.includes(".")
                          ? header.req.key
                              .split(".")
                              .reduce((a: string, b: any) => a[b], data)
                          : data[header.req.key || ""]}
                      </Text>
                    </>
                  ) : (
                    <Text
                      key={index}
                      style={{
                        ...styles.data,
                        width: header.width,
                        color:
                          data.urgent && header.key === "required"
                            ? "red"
                            : "black",
                      }}
                    >
                      {header?.key?.includes(".")
                        ? header.key.split(".").reduce((a, b) => a[b], data)
                        : data[header.key || ""]}
                    </Text>
                  );
                })}
              </View>
            );
          })}
        </View>
        {/* End Of Content */}

        {/* Footer */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export { PDFDocument };
