import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser?.role === "ADMIN" ? (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Admin
              </span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to="/crafted/accounts"
            title="Master Data"
            icon="/media/icons/duotune/communication/com006.svg"
            fontIcon="bi-person"
          >
            <SidebarMenuItem
              to="/apps/user-management/users"
              title="User"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/apps/role-management/roles"
              title="Role"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/apps/store-management/stores"
              title="Store"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/apps/agent-management/agents"
              title="Agent"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/apps/produce-management/produces"
              title="Produce"
              hasBullet={true}
            />
            <SidebarMenuItem
              to="/apps/produce-type-management/produce-types"
              title="Produce Type"
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </>
      ) : (
        <></>
      )}

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Operation
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/crafted/accounts"
        title="Stock On Hand"
        icon="/media/icons/duotune/general/gen001.svg"
        fontIcon="bi-person"
      >
        {currentUser?.role === "ADMIN" || currentUser?.role === "USER" ? (
          <SidebarMenuItem
            to="/apps/stock-management/list"
            title="Stock On Hand"
            hasBullet={true}
          />
        ) : (
          <></>
        )}
        <SidebarMenuItem
          to="/apps/stock-management/report"
          title="Consolidated Stock"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/crafted/accounts"
        title="Order"
        icon="/media/icons/duotune/ecommerce/ecm006.svg"
        fontIcon="bi-person"
      >
        {currentUser?.role === "ADMIN" || currentUser?.role === "USER" ? (
          <SidebarMenuItem
            to="/apps/order-management/list"
            title="Request Order"
            hasBullet={true}
          />
        ) : (
          <></>
        )}
        <SidebarMenuItem
          to="/apps/order-management/report"
          title="Consolidated Order"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Configuration
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/access-setting"
        title="Access Setting"
        icon="/media/icons/duotune/general/gen049.svg"
        fontIcon="bi-person"
      />
      {currentUser?.role === "ADMIN" ? (
        <SidebarMenuItem
          to="/cron-setting"
          title="Cron Setting"
          icon="/media/icons/duotune/coding/cod001.svg"
          fontIcon="bi-person"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { SidebarMenuMain };
